import React from 'react'
import {graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import Header from '../components/header'
import Footer from '../components/footer'
import SEO from '../components/seo'
import styles from './roadmap.module.css'
const stripMarkdownOneline = require('strip-markdown-oneline')

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    #allGitlabIssue {
    #  edges {
    #    node {
    #      title
    #      iid
    #      state
    #      labels {
    #        name
    #        description
    #        color
    #        text_color
    #        priority
    #      }
    #      description
    #      assignees {
    #        username
    #      }
    #    }
    #  }
    #}

    allAuthorsCsv {
      edges {
        node {
          handle
          name
          picture
          gitlab
        }
      }
    }

    allImageSharp(filter: {original: {src: {regex: "/team-.*/"}}}) {
      edges {
        node {
          fixed(width: 25) {
            src
            srcSet
            width
            height
            originalName
          }
        }
      }
    }
  }
`

function ellipsize(str, len = 120) {
  return str.length > len ? str.slice(0, len) + '...' : str
}

function Epic(props) {
  const {iid, title, dark, label, description, assignees, showLabel} = props
  const className = dark ? styles.epicDark : styles.epic
  const labelName = label.description.split('(')[0].trim()
  const labelBackgroundColor = label.color
  const labelColor = label.text_color
  return (
    <Link to={`/epic/${iid}`} className={className}>
      <h3>{title}</h3>
      {typeof showLabel === 'undefined' || !!showLabel ? (
        <div className={styles.epicStatus}>
          <div
            className={styles.label}
            style={{
              backgroundColor: labelBackgroundColor,
              color: labelColor,
            }}
          >
            {labelName}
          </div>
          {assignees.map(assignee => (
            <Img
              key={assignee.username}
              fixed={assignee.pictureFixed}
              className={styles.assignee}
            />
          ))}
        </div>
      ) : null}
      <p>{ellipsize(stripMarkdownOneline(description))}</p>
      <span className={styles.readMore}>Read more →</span>
    </Link>
  )
}

function FutureEpic(props) {
  const {title} = props
  return <li style={{fontSize: '18px'}}>{title}</li>
}

export default function Roadmap({data}) {
  const {allGitlabIssue, allAuthorsCsv, allImageSharp, site} = data

  const pictures = allImageSharp.edges.map(({node}) => node.fixed)

  //const epicsWithAuthors = allGitlabIssue.edges.map(({node}) => {
  //  if (node.assignees.length > 0) {
  //    for (let i = 0; i < node.assignees.length; i++) {
  //      const username = node.assignees[i].username
  //      const author = allAuthorsCsv.edges.find(
  //        ({node: author}) => author.gitlab === username
  //      ).node
  //      node.assignees[i].name = author.name
  //      const picFixed = pictures.find(p => p.originalName === author.picture)
  //      if (picFixed) node.assignees[i].pictureFixed = picFixed
  //    }
  //  }
  //  return node
  //})

  //function getEpicsFor(labelName, allowClosed = true) {
  //  return epicsWithAuthors
  //    .filter(issue => (allowClosed ? true : issue.state !== 'closed'))
  //    .filter(
  //      ({labels}) =>
  //        labels.findIndex(label => label.name === labelName) >= 0 &&
  //        labels.findIndex(label => label.name.startsWith('theme::')) >= 0
  //    )
  //    .map(issue => {
  //      issue.label = issue.labels.find(label =>
  //        label.name.startsWith('phase::')
  //      )
  //      return issue
  //    })
  //}

  // Ongoing
  //const promotion = getEpicsFor('phase::promotion', false)
  //const devWIP = getEpicsFor('~ work in progress', false)
  //const devTesting = getEpicsFor('~ testing', false)
  //const stakeholderReview = getEpicsFor('phase::stakeholder-review', false)
  //const discovery = getEpicsFor('phase::discovery', false)
  //const scoping = getEpicsFor('phase::scoping', false)
  //const present = []
  //  .concat(
  //    scoping,
  //    discovery,
  //    stakeholderReview,
  //    devWIP,
  //    devTesting,
  //    promotion
  //  )
  //  .sort((a, b) => a.label.priority - b.label.priority)

  // Future
  //const upcoming = getEpicsFor('phase::upcoming', false)
  //const nearFuture = getEpicsFor('phase::near-future', false)
  //const farFuture = getEpicsFor('phase::far-future', false)

  const title = 'Roadmap'
  return (
    <>
      <SEO title={title} />
      <Header siteTitle={site.siteMetadata.title} />

      <div className={styles.container}>
        <h1 className={styles.title}>{title}</h1>
        <p>
          Manyverse is constantly improving, and this page shows you what we're
          working on now, and what we plan to add. See also the{' '}
          <Link to={'/features'}>features we completed</Link>. Click on each
          epic below to read more details about them.
        </p>
      </div>

      <div className={styles.container}><p>Unable to load roadmap</p></div>
      {/*
      {present.length === 0 ? null : (
        <div className={styles.skewyContainer}>
          <div className={styles.skewy} />
          <div className={styles.container}>
            <h2>Ongoing</h2>
            <div className={styles.epics}>
              {present.map(issue => (
                <Epic key={issue.iid} {...issue} dark={true} />
              ))}
            </div>
          </div>
        </div>
      )}

      <div className={styles.container}>
        <h2>Upcoming</h2>
        <div className={styles.epics}>
          {upcoming.map(issue => (
            <Epic key={issue.iid} {...issue} showLabel={false} />
          ))}
        </div>

        <h2>Near future</h2>
        <ul className={styles.futureEpics}>
          {nearFuture.map(issue => (
            <FutureEpic key={issue.iid} {...issue} showLabel={false} />
          ))}
        </ul>

        <h2>Far future</h2>
        <ul className={styles.futureEpics}>
          {farFuture.map(issue => (
            <FutureEpic key={issue.iid} {...issue} showLabel={false} />
          ))}
        </ul>
      </div>
      */}
      <Footer />
    </>
  )
}
